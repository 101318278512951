<template>
  <div id="cashCollection">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="12" class="title-col">
        <div class="title">
          <h1>{{ $t("Cash Collection") }}</h1>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <div class="box">
          <h4 class="second-title">{{ $t("Payment Type") }}</h4>
          <ul class="payment-methods text-center">
            <li class="text-center disabled">
              <p class="icon">
                <i class="fas fa-money-check"></i>
              </p>
              <p class="text">{{ $t("Visa") }}</p>
            </li>

            <li
              class="text-center"
              :class="data.transaction.type != 'Cash' ? 'disabled' : ''"
            >
              <p
                class="icon selectable"
                :class="data.transaction.type == 'Cash' ? 'active' : ''"
              >
                <i class="far fa-money-bill-alt"></i>
              </p>
              <p class="text">{{ $t("Cash") }}</p>
            </li>

            <li
              class="text-center"
              :class="
                data.transaction.type != 'Bank Transfer' ? 'disabled' : ''
              "
            >
              <p
                class="icon selectable"
                :class="
                  data.transaction.type == 'Bank Transfer' ? 'active' : ''
                "
              >
                <i class="fas fa-dollar-sign"></i>
              </p>
              <p class="text">{{ $t("Bank Transfer") }}</p>
            </li>

            <li
              class="text-center"
              :class="data.transaction.type != 'Check' ? 'disabled' : ''"
            >
              <p
                class="icon selectable"
                :class="data.transaction.type == 'Check' ? 'active' : ''"
              >
                <i class="far fa-credit-card"></i>
              </p>
              <p class="text">{{ $t("Check") }}</p>
            </li>
          </ul>
        </div>
        <div class="selectInput">
          <label for="select payment" class="main-color">{{
            $t("Cash Point")
          }}</label>
          <p class="text">{{ data.transaction.cash_point }}</p>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="box">
          <v-row>
            <v-col cols="12" md="6">
              <p class="key">{{ $t("Transaction Id") }}</p>
              <p class="value">{{ data.transaction.transaction_id }}</p>
            </v-col>
            <v-col cols="10" md="5">
              <p class="key">{{ $t("Transaction Date") }}</p>
              <p class="text">{{ data.transaction.transaction_date }}</p>
            </v-col>
            <v-col cols="2" md="1">
              <v-icon
                medium
                style="float: right; opacity: 0.5; margin-top: 40px"
                class="mr-2 main-color dateIcon"
                >event</v-icon
              >
            </v-col>
            <v-row
              v-if="
                data.transaction.type == 'Cash' ||
                data.transaction.type == 'onlinePayment'
              "
            >
              <v-col cols="12" md="6" style="padding-top: 0">
                <p class="key">{{ $t("Voucher Number") }}</p>
                <p class="value">{{ data.transaction.voucher_number }}</p>
              </v-col>
            </v-row>
            <v-row v-if="data.transaction.type == 'Bank Transfer'">
              <v-col cols="12" md="6" style="padding-top: 0">
                <p class="key">{{ $t("Transfer Number") }}</p>

                <p class="value">{{ data.transaction.transfer_number }}</p>
              </v-col>
              <v-col cols="6" md="5" style="padding-top: 0">
                <p class="key">{{ $t("Transfer Date") }}</p>

                <p class="value">{{ data.transaction.transfer_date }}</p>
              </v-col>
              <v-col cols="6" md="1">
                <v-icon
                  medium
                  style="float: right; opacity: 0.5; margin-top: 28px"
                  class="mr-2 main-color dateIcon"
                  >event</v-icon
                >
              </v-col>
            </v-row>
            <v-row v-if="data.transaction.type == 'Check'">
              <v-col cols="12" md="6" style="padding-top: 0">
                <p class="key">{{ $t("Check Number") }}</p>
                <p class="value">{{ data.transaction.check_number }}</p>
              </v-col>
              <v-col cols="6" md="5" style="padding-top: 0">
                <p class="key">{{ $t("Check Date") }}</p>
                <p class="value">{{ data.transaction.check_date }}</p>
              </v-col>
              <v-col cols="6" md="1">
                <v-icon
                  medium
                  style="float: right; opacity: 0.5; margin-top: 28px"
                  class="mr-2 main-color dateIcon"
                  >event</v-icon
                >
              </v-col>
            </v-row>
          </v-row>
        </div>
        <div class="textAreaInput">
          <label for="textarea">{{ data.transaction.notes_name }}</label>
          <p class="text">{{ data.transaction.notes }}</p>
        </div>
      </v-col>
    </v-row>
    <hr class="basic" />
    <v-img :src="data.transaction.receipt"></v-img>

    <v-row>
      <v-col cols="12" md="2">
        <h3 class="second-title">{{ $t("Payment Details") }}</h3>
      </v-col>
      <v-col cols="12" md="10" class="info-list">
        <v-row>
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("Name") }}:</strong>
              {{ data.invoice.applicant_datails.applicant_name }}
            </p></v-col
          >
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("Partner Group") }}:</strong>
              {{ data.invoice.applicant_datails.partner_group }}
            </p></v-col
          >
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("ID") }}:</strong>
              {{ data.invoice.applicant_datails.applicant_code }}
            </p></v-col
          >
          <v-col cols="6" md="3"
            ><p class="value">
              <strong>{{ $t("Invoice Number") }}:</strong>
              {{ data.invoice.invoice.invoice_number }}
            </p></v-col
          >
        </v-row>
      </v-col>
    </v-row>

    <hr class="basic" />

    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :options.sync="options"
          :headers="headers"
          :items="items"
          class="elevation-1 level1"
          loading-text="Loading... Please wait"
          hide-default-footer
        >
          <template v-slot:item.counter="{ item }">
            <span>{{ items.indexOf(item) + 1 }}</span>
          </template>

          <template v-slot:item.due_date="{ item }">
            <v-icon
              v-if="item.type != 'tax'"
              color="#ff8972"
              class="calendar-icon"
              >calendar_today</v-icon
            >
            <span class="due-date">{{ item.due_date }}</span>
          </template>
          <template v-slot:item.net_amount="{ item }">
            <p v-if="item.type != 'tax' && !item.taxable">
              {{ item.amount - item.discount }}
            </p>
            <p v-if="item.type != 'tax' && item.taxable">
              {{
                item.amount +
                (item.amount * items[items.length - 1]["due_amount"]) / 100 -
                item.discount
              }}
            </p>
          </template>
          <template v-slot:item.amount="{ item }">
            <p v-if="item.type != 'tax' && !item.taxable">
              {{ item.amount }}
            </p>
            <p v-if="item.type != 'tax' && item.taxable">
              {{
                item.amount +
                (item.amount * items[items.length - 1]["due_amount"]) / 100
              }}
            </p>
            <p v-if="item.type == 'tax'">{{ item.amount }}</p>
          </template>

          <template v-slot:item.due_amount="{ item }">
            <span v-if="item.type == 'tax'">{{ item.due_amount }}%</span>
            <span v-if="item.type != 'tax'">{{ item.due_amount }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <hr class="second" />

    <v-row>
      <v-col cols="6" md="2" sm="6">
        <p class="stats">
          <strong>{{ $t("Total Before Tax") }}: </strong>
          {{ data.invoice.invoice.total_before_tax }}
        </p>
      </v-col>
      <v-col cols="6" md="2" sm="6">
        <p class="stats">
          <strong>{{ $t("Tax Amount") }}: </strong>
          {{ data.invoice.invoice.tax_amount }}
        </p>
      </v-col>
      <v-col cols="6" md="2" sm="6">
        <p class="stats">
          <strong>{{ $t("Total") }}: </strong> {{ data.invoice.invoice.total }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total After Discount") }}: </strong>
          {{ data.invoice.invoice.total_after_discount }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total Amount Paid") }}: </strong> {{ totalAmountPaid }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Invoice",
  data() {
    return {
      submitted: false,
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "counter",
        },
        { text: this.$i18n.t("Fee Type"), value: "item", sortable: false },
        {
          text: this.$i18n.t("Due Amount"),
          value: "due_amount",
          sortable: false,
        },
        { text: this.$i18n.t("Due Date"), value: "due_date", sortable: false },
        {
          text: this.$i18n.t("Net After Tax"),
          value: "amount",
          sortable: false,
        },
        { text: this.$i18n.t("Discount"), value: "discount", sortable: false },
        {
          text: this.$i18n.t("Amount Paid"),
          value: "amount_paid",
          sortable: false,
        },
        {
          text: this.$i18n.t("Net After Discount"),
          value: "net_amount",
          sortable: false,
        },
        { text: this.$i18n.t("Notes"), value: "notes", sortable: false },
        {
          text: this.$i18n.t("Remaining Amount"),
          value: "remaining_amount",
          sortable: false,
        },
      ],
      payment_id: "",
      data: [],
      items: [],
    };
  },
  computed: {
    totalAfterDiscount: {
      get() {
        let totalDisount = this.total;
        this.items.forEach((item) => {
          if (item.discount != "" && item.discount != undefined) {
            totalDisount -= item.discount;
          }
        });
        return totalDisount;
      },
      // setter
      set: function (newValue) {
        this.totalAfterDiscount = newValue;
      },
    },
    totalAmountPaid: {
      get() {
        let totalPaid = 0;
        this.items.forEach((item) => {
          // console.log(item);
          if (item.amount_paid != "" && item.amount_paid != undefined) {
            totalPaid += Number(item.amount_paid);
          }
        });
        return totalPaid;
      },
      // setter
      set: function (newValue) {
        this.totalAmountPaid = newValue;
      },
    },
  },
  mounted() {
    this.payment_id = this.$router.currentRoute.params.id;

    axios
      .get(this.getApiUrl + "/payments/show/" + this.payment_id, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.data = response.data.data;
        this.items = this.data.invoice.items;
        // console.log(response.data);
      });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.info-list {
  .value {
    font-size: 13px;
  }
}
.paymentBtn {
  padding-top: 6px !important;
}
hr.basic {
  margin: 0;
}
hr.second {
  border: 2px solid $main-color;
  margin: 0 auto;
  background-color: $main-color;
}
.v-data-table th {
  height: 25px;
}

.box {
  box-shadow: $box-shadow;
  width: 90%;
  padding: 10px 10px;
  margin-bottom: 1rem;
  height: 222px;
  .key {
    margin-bottom: 8px;
  }
  .value {
    color: $main-gray;
    margin-bottom: 8px;
  }
}

.payment-methods {
  padding-left: 0;
  li {
    display: inline-block;
    width: 25%;

    .icon {
      font-size: 2rem;
      color: $main-color;
      margin-bottom: 8px;
      padding: 5px 20px 5px;
      margin: 0 5px;
      border-radius: 5px;
    }
    .text {
      color: $main-color;
    }
  }
}

.active {
  background-color: $main-backgroundColor;
}

.selectable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;

  .icon {
    color: $main-gray !important;
  }

  .text {
    color: $main-gray !important;
  }
}

.selectInput {
  width: 90%;
  // padding-left: 12px;
  .v-input {
    max-width: 70%;
  }
}

.textAreaInput {
  // padding-left: 12px;
  width: 90%;
  label {
    color: $main-color;
  }
}

.calendar-icon {
  padding-right: 5px;
}
.due-date {
  color: #ff8972;
}

.second-title {
  color: $main-color;
}

@media (max-width: 600px) {
  .second-title {
    margin-bottom: 0;
  }
  .box {
    height: auto;
    width: 100%;
    .payment-methods {
      li {
        .icon {
          padding: 5px 10px 5px;
        }
      }
    }
  }

  .info-list .value {
    margin-bottom: 0;
  }

  .selectInput {
    width: 100%;
    .v-input {
      max-width: 100%;
    }
  }

  .textAreaInput {
    width: 100%;
  }
}
</style>
